import Loadable from '../../components/Loadable';
import { lazy } from 'react';

const AddBilty = Loadable(lazy(() => import('./addbilty/AddBilty')));
const AllBilty = Loadable(lazy(() => import('./addbilty/AllBilty')));
const PrintBilty = Loadable(lazy(() => import('./biltydetails/PrintBilty')));
const BiltyDetails = Loadable(lazy(() => import('./biltydetails/BiltyDetails')));

const biltyRoutes = [
  { path: '/addbilty', element: <AddBilty /> },
  { path: '/allbilty', element: <AllBilty /> },
  { path: '/details', element: <PrintBilty /> },
  { path: '/biltyinfo', element: <BiltyDetails /> },
];

export default biltyRoutes;
