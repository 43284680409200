import { authRoles } from './auth/authRoles';

export const navigations = [
  { name: 'Dashboard', path: '/admindashboard', icon: 'dashboard', auth: authRoles.GUEST },
  { name: 'Pending PODs', path: '/pendingpod', icon: 'bus_alert', auth: authRoles.DRIVER },
  {
    name: 'Customer Enquiry',
    path: '/customer_enquiry',
    icon: 'support_agent',
    auth: authRoles.ADMIN,
  },
  { label: 'Bilty & Memo', type: 'label', auth: authRoles.ADMIN },
  {
    name: 'Bilty',
    icon: 'storage',
    children: [
      { name: 'Add Bilty', iconText: 'AB', path: '/addbilty', auth: authRoles.ADMIN },
      { name: 'Biltie Reports', iconText: 'Reports', path: '/allbilty', auth: authRoles.ADMIN },
    ],
  },
  {
    name: 'Memo',
    icon: 'receipt',
    children: [
      { name: 'Create Memo', iconText: 'Memo', path: '/memo', auth: authRoles.ADMIN },
      {
        name: 'Regular Memo Reports',
        iconText: 'Reports',
        path: '/memoreport',
        auth: authRoles.ADMIN,
      },
      { name: 'Bulk Print', iconText: 'Reports', path: '/memolist', auth: authRoles.ADMIN },
    ],
  },
  { label: 'Reports', type: 'label', auth: authRoles.ADMIN },
  {
    name: 'Paid Reports',
    icon: 'summarize',
    children: [
      { name: 'Dashboard', iconText: 'paid', path: '/paidbilty', auth: authRoles.ADMIN },
      // { name: 'Pending', iconText: 'pending', path: '/pendingpaid', auth: authRoles.ADMIN },
      { name: 'Receipt', iconText: 'paid', path: '/paidreceipt', auth: authRoles.ADMIN },
    ],
  },

  { label: 'Finance', type: 'label', auth: authRoles.SA },
  {
    name: 'Account',
    icon: 'account_balance',
    children: [
      { name: 'Dashboard', iconText: 'Acc', path: '/accdashboard', auth: authRoles.SA },
      // { name: 'Payment', iconText: 'Payment', path: '/payment', auth: authRoles.SA },
      // { name: 'Receipt', iconText: 'Receipt', path: '/receipt', auth: authRoles.SA },
      // { name: 'Account Master', iconText: 'Master', path: '/accmaster', auth: authRoles.SA },
      // { name: 'Ledger', iconText: 'Ledger', path: '/ledger', auth: authRoles.SA },
    ],
  },

  { label: 'Bill', type: 'label', auth: authRoles.SA },
  {
    name: 'Bills',
    icon: 'receipt_long',
    children: [
      { name: 'Create Bill', iconText: 'Bill', path: '/bill', auth: authRoles.SA },
      { name: 'Bill Reports', iconText: 'Reports', path: '/billlist', auth: authRoles.SA },
    ],
  },
  { label: 'MASTER', type: 'label', auth: authRoles.ADMIN },
  {
    name: 'Masters',
    icon: 'format_list_bulleted',
    children: [
      { name: 'City', iconText: 'City', path: '/master/City', auth: authRoles.ADMIN },
      {
        name: 'Consignor',
        iconText: 'Consignor',
        path: '/master/consignor',
        auth: authRoles.ADMIN,
      },
      {
        name: 'Consignee',
        iconText: 'Consignee',
        path: '/master/consignee',
        auth: authRoles.ADMIN,
      },
      { name: 'Route', iconText: 'Route', path: '/master/routes', auth: authRoles.ADMIN },
      { name: 'Driver', iconText: 'Driver', path: '/master/driver', auth: authRoles.ADMIN },
      { name: 'Vehicle', iconText: 'Vehicle', path: '/master/Vehicle', auth: authRoles.ADMIN },
      { name: 'Quotation', iconText: 'Quotation', path: '/master/quotation', auth: authRoles.SA },
    ],
  },
  { label: 'Components', type: 'label', auth: authRoles.SA },
  {
    name: 'User Info',
    icon: 'person_add',
    children: [
      {
        name: 'Register User',
        iconText: 'R',
        path: '/signup',
        auth: authRoles.SA,
      },
      {
        name: 'Users List',
        iconText: 'L',
        path: '/userlist',
        auth: authRoles.SA,
      },
    ],
  },
];
