import {
  QUOT_LIST,
  CREATE_QUOT_LIST,
  CREATE_QUOT,
  DELETE_QUOT,
  RESET_QUOT_LIST,
} from '../constant/constant';

// const initialState = [];
const initialState = {
  norname: '',
  norcity: '',
  neecity: '',
  mop: '',
  pkgtype: '',
  frtype: '',
  rate: '',
};

export const QuotReducer = function (state = [], action) {
  switch (action.type) {
    case QUOT_LIST: {
      return [...action.payload];
    }

    case CREATE_QUOT: {
      return [...state, action.payload];
    }

    case DELETE_QUOT: {
      return state.filter(({ _id }) => _id !== action.payload._id);
    }
    //   case EDIT_NOR: {
    //     return state.map((state) => {
    //       if (state._id === action.payload._id) {
    //         return {
    //           ...state,
    //           ...action.payload,
    //         };
    //       } else {
    //         return state;
    //       }
    //     });
    //   }
    default: {
      return state;
    }
  }
};

// export default QuotReducer;

// const initialData = [];
export const QuotListReducer = function (state = initialState, action) {
  switch (action.type) {
    case CREATE_QUOT_LIST: {
      return action.payload;
    }
    case RESET_QUOT_LIST: {
      return action.payload;
    }

    //   case DELETE_NOR: {
    //     return state.filter(({ _id }) => _id !== action.payload._id);
    //   }
    //   case EDIT_NOR: {
    //     return state.map((state) => {
    //       if (state._id === action.payload._id) {
    //         return {
    //           ...state,
    //           ...action.payload,
    //         };
    //       } else {
    //         return state;
    //       }
    //     });
    //   }
    default: {
      return state;
    }
  }
};
